import React from "react";
// import { PropTypes } from "prop-types";
import { Text } from "@wingmate/toolkit";
import { Cell } from "@wingmate/graphs";
import { AnalyticsHandshakeIcon } from "@wingmate/graphics";

export function HealthCell() {
  return (
    <Cell
      rowspan={2}
      colspan={2}
      position={{ col: 1, row: 2 }}
      icon={<AnalyticsHandshakeIcon width={44} height={44} />}
      color="#DAF3FE"
    >
      <Text>Health Chart Here</Text>
    </Cell>
  );
}

HealthCell.propTypes = {
  // health: PropTypes.object,
};

export default HealthCell;
